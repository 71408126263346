import { http } from '@/http/axios.js'
export function getDataStaticAPI(station_id) {
  return http({
    url: '/company/homepage/order/static',
    method: 'get',
    params: { station_id }
  })
}
export function getChartStatAPI(station_id) {
  return http({
    url: '/company/index/dataStat',
    method: 'get',
    params:{station_id}
  })
}
