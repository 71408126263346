<template>
  <div ref="line" class="line"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'BarChart',
  props: {
    title: {
      type: String,
      default: ''
    },
    list_x: {
      type: Array,
      default: () => []
    },
    series: {
      type: Array,
      default: () => []
    },
    legend: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    list_x: {
      handler(newV, oldV) {
        this.init()
      }
    },
    series: {
      handler(newV, oldV) {
        this.init()
      }
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.myChart = echarts.init(this.$refs.line)
      this.myChart.setOption({
        title: {
          text: this.title,
          left: 'center',
          top: '20',
          textStyle: { color: '#1E6BEB', fontSize: '16px' }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: '25%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.list_x,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: { interval: 0, rotate: 30 }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: { show: true },
            axisTick: { show: true }
          }
        ],
        legend: {
          data: this.legend
        },
        series: this.series
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.line {
  width: 100%;
  height: 100%;
}
</style>
